// ProjectCard.js
import React from 'react';
import './ProjectCard.css';

function ProjectCard({ project }) {
  return (
    <div className="project-card ">

      <div className="project-card-content">
        <h3>{project.title}</h3>
        <p>{project.description}</p>
        <div className="mt-auto">
            <a
            href={project.link}
            className="content-button"
            target="_blank"
            rel="noopener noreferrer"
            >
              {project.buttonText}
            </a>
        </div>
      </div>

      {/* Right Side: Image */}
      <div
        className="project-card-image"
        style={{ backgroundImage: `url(${project.image})` }}
      ></div>

      {/* Overlapping Glass Effect */}
      <div className="project-card-glass-overlay"></div>
    </div>
  );
}

export default ProjectCard;
