import React, { useState, useRef, useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import SplineRobot from "../components/SplineRobot";
import Socials from "../components/Socials";
import { useNavigate } from "react-router-dom";
import { FaGithub } from 'react-icons/fa';



function Home() {
  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const [showSocials, setShowSocials] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const calculateYearsOfExperience = (startDate) => {
      const start = new Date(startDate);
      const today = new Date();
      let years = today.getFullYear() - start.getFullYear();
      const monthDiff = today.getMonth() - start.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < start.getDate())
      ) {
        years--;
      }
      return years;
    };

    const startDate = "2019-09-01";
    const years = calculateYearsOfExperience(startDate);
    setYearsOfExperience(years);
  }, []);

  const MemoizedSplineRobot = useMemo(() => <SplineRobot />, []);

  const handleResumeClick = () => {
    const pdfUrl = process.env.PUBLIC_URL + "/Derek_Miller_Resume.pdf";
    window.open(pdfUrl, "_blank");
  };

  const handleContactClick = () => {

    
    navigate("/contact");


  };

  const handleEducationClick = () => {
    navigate("/experience#educationElement");
  };

  const handleExperienceClick = () => {
    navigate("/experience#experienceElement");
  };

  return (
    <div className="d-flex flex-column min-vh-100 justify-content-center pt-16">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="contentOuter ">
          <div className="contentInner row g-4">
            
            <div className="row align-items-center mb-0 mt-0 d-none d-md-flex">
              <div className="col"></div>
              <div className="col-auto">{showSocials && <Socials />}</div>
            </div>

            <div className="col-12 col-md-6">
              <div className="contentCard d-flex flex-column">
                <div className="flex-grow-1">
                  <div className="contentCardHeader">
                    <h2>Full-Stack Web Development</h2>
                    <lord-icon
                      src="https://cdn.lordicon.com/rzbxngpa.json"
                      trigger="hover"
                      stroke="bold"
                      colors="primary:#2f709e,secondary:#333"
                      target=".contentCard"
                      style={{ width: "4rem", height: "4rem" }}
                    ></lord-icon>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginBottom: "10px",
                      fontSize: "1.4rem",
                    }}
                  >
                    {/* <a
                      href="https://github.com/DerekM84"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="LinkedIn Profile"
                    >
                     <FaGithub />
                    </a> */}
                   




                    <motion.span
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 1.2 }}
                      style={{ color: "#444", margin: 0, fontSize: "2rem" }}
                    >
                      Derek Miller
                    </motion.span>
                  
                    {/* <span style={{ color: "#444", margin: 0,fontSize: "1.7rem"}}>Derek Miller</span> */}
                  </div>

                  <p>
                    I have a passion for creating custom solutions in software
                    development. For over {yearsOfExperience} years, I've worked
                    across both front and back-end simultaneously, becoming
                    proficient and well rounded. I'm always looking for new and
                    exciting projects to work on.
                  </p>
                </div>

                <div
                  className="mt-auto d-flex justify-content-end"
                  style={{ gap: "15px" }}
                >
                  <button
                    className="content-button"
                    type="button"
                    onClick={handleContactClick}
                  >
                    Contact Derek
                  </button>
                  <button
                    className="content-button"
                    type="button"
                    onClick={handleResumeClick}
                  >
                    View Résumé
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="contentReverseCard">{MemoizedSplineRobot}</div>
            </div>

            <div className="col-12 col-md-4">
              <div className="contentCard d-flex flex-column">
                <div className="flex-grow-1">
                  <div className="contentCardHeader">
                    <h3>Experience</h3>
                    <lord-icon
                      src="https://cdn.lordicon.com/jxjfjyri.json"
                      trigger="hover"
                      stroke="bold"
                      colors="primary:#2f709e,secondary:#333"
                      target=".contentCard"
                      style={{ width: "4rem", height: "4rem" }}
                    ></lord-icon>
                  </div>
                  <p>
                    Developing primarily for a live production environment at a
                    large scale SaaS company in the mining industry.{" "}
                  </p>
                </div>
                <div className="mt-auto d-flex justify-content-end">
                  <button
                    className="content-button"
                    type="button"
                    onClick={handleExperienceClick}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="contentCard d-flex flex-column">
                <div className="flex-grow-1">
                  <div className="contentCardHeader">
                    <h3 >Education</h3>
                    <lord-icon
                      src="https://cdn.lordicon.com/dqrpfjei.json"
                      trigger="hover"
                      stroke="bold"
                      colors="primary:#2f709e,secondary:#333"
                      target=".contentCard"
                      style={{ width: "4rem", height: "4rem" }}
                    ></lord-icon>
                  </div>
                  <p>
                    After studying at University of Arizona in 2019, I'm now
                    keeping up to date with evolving technologies, such as advancements in A.I.
                  </p>
                </div>
                <div className="mt-auto d-flex justify-content-end">
                  <button
                    className="content-button"
                    type="button"
                    onClick={handleEducationClick}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="contentCard">
                <div className="contentCardHeader">
                  <h3>Technical Skills</h3>
                  <lord-icon
                    src="https://cdn.lordicon.com/nmguxqka.json"
                    trigger="hover"
                    stroke="bold"
                    colors="primary:#2f709e,secondary:#333"
                    target=".contentCard"
                    style={{ width: "4rem", height: "4rem" }}
                  ></lord-icon>
                </div>
                <div
                  className="contentReverseCard"
                  style={{
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    textAlign: "center",
                  }}
                >
                  React / AngularJs / mySQL / Javascript + Jquery / HTML / CSS /
                  PHP / Ionic / Capacitor / Cordova / Node.js
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Home;
