// import React from 'react';
// import Spline from '@splinetool/react-spline';

// const SplineRobot = () => {
//     return (
//         <div className="spline-wrapper">
//             <div className="spline-container">
//                 <Spline scene="https://prod.spline.design/d2loRCJ1RiPIUlJJ/scene.splinecode"/>
//             </div>
//         </div>
//     );
// };

// export default SplineRobot;






import React, { lazy, Suspense } from 'react';

   const LazySpline = lazy(() => import('@splinetool/react-spline'));

   const SplineRobot = () => {
       return (
           <div className="spline-wrapper ">
               <div className="spline-container ">
                   <Suspense fallback={<div>Loading...</div>}>
                       <LazySpline scene="https://prod.spline.design/d2loRCJ1RiPIUlJJ/scene.splinecode"/>
                   </Suspense>
               </div>
           </div>
       );
   };

   export default SplineRobot;

//    https://prod.spline.design/d2loRCJ1RiPIUlJJ/scene.splinecode
