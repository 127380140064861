import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import ProjectCard from "../components/ProjectCard.js"; 
import { useLocation } from 'react-router-dom';



function Experience() {

    const primarySkills = ['React', 'JavaScript', 'CSS', 'HTML', 'SQL', 'PHP','Git', 'Node.js', 'AngularJS', 'Ionic + Capacitor'];
    const secondarySkills = ['Github', 'Postman', 'Add stuff here', 'Add stuff here'];
    const Editors = ['VSCode', 'Sublime', 'Cursor'];

    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const elementId = location.hash.replace('#', '');
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);


    
        const experienceData = [
          {
            title: " Subset - (BlackFox Exploration)",
            description: "Developing a full product stack to assist clients in the mining industry. The platform allows for the tracking of mining activity and drilling project performance analytics.",
            image: "/blackfox.png",
            // link: "https://github.com/yourusername/project-one",
          }
        ]


          const projectData = [
          {
            title: "National Parks Data",
            description: "Learn about national parks in your state, get directions to the park, and stay informed about recent weather conditions.",
            image: "/Natparks.png",
            buttonText: "View",
            link: "https://dthayer16.github.io/National-Park-Data-Project/index.html",
          },
          {
            title: "Future Pet Emporium",
            description: "Interface with a pet store from the future. This app offers a digital point of sale, wallet, and inventory management system through the command line.",
            image: "/FPE.png",
            buttonText: "View github",
            link: "https://github.com/DerekM84/StoreFront?tab=readme-ov-file",
          },
          {
            title: "Train Scheduler",
            description: "Track and monitor train schedules based on departure, frequency and delay. This web-app utilizes Moment.js to handle an assortment of date time conversions, a core skill for any developer.",
            image: "/Trainscheduler.png",
            buttonText: "View",
            link: "https://derekm84.github.io/Train-Scheduler/",
          },
          {
            title: "React Memory Game",
            description: "Test your short term memory capacity with look-alike tiles in this mini-game, designed to demonstrate the use of React state management, props, and reusable functional components.",
            image: "/memorygame.png",
            buttonText: "View github",
            link: "https://github.com/DerekM84/react-memory",
          },
          {
            title: "Garage Trader",
            description: "Barter with nearby neighbors using this community garage-sale application. Members can register, post items, and browse available items nearby.",
            image: "/garagetrader.png",
            buttonText: "View github",
            link: "https://github.com/tannerbodrero/project-3?tab=readme-ov-file",
          }
        ];


        const educationData = [
          {
            title: "University of Arizona",
            description: "MERN Stack Development Program",
            image: "/UoA.png",
            buttonText: "Learn More",
            link: "https://ce.arizona.edu/classes/coding-boot-camp",
          },
        ]



  return (
    <div className="pt-16">
    <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
        >


      <div className="contentOuter glass">

          <div className="contentInner">
            <h2 id="experienceElement">Experience</h2>
            <div className="row g-4">
              {experienceData.map((project, index) => (
                <div className="col-12 col-md-8" key={index}>
                  <ProjectCard project={project} />
                </div>
              ))}
            </div>
            <br/>
            <h2 id="projectsElement">Projects</h2>
            <div className="row g-4">
              {projectData.map((project, index) => (
                <div className="col-12 col-md-8" key={index}>
                  <ProjectCard project={project} />
                </div>
              ))}
            </div>
            <br/>

            <h2 id="educationElement">Education</h2>
            <div className="row g-4">
              {educationData.map((project, index) => (
                <div className="col-12 col-md-8" key={index}>
                  <ProjectCard project={project} />
                </div>
              ))}
            </div>
          </div>
          <br/>

        </div>

        
      <div className="contentOuter glass">
        <div className="contentInner row g-4">
          <div className="col-12 col-md-12">
            <div className="contentCard d-flex flex-column">
              <div className="skills-section mb-5">
                <h3 className="mb-3">Technical Skills</h3>
                <div className="d-flex flex-wrap">
                  {primarySkills.map((skill, index) => (
                    <motion.div
                      key={index}
                      className="skill-tag m-1 p-2 bg-light rounded"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {skill}
                    </motion.div>
                  ))}
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>


                    


    </motion.div>
  </div>
  );
}

export default Experience;




