import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
  return (
    <div className="navbar-wrapper fixed">
      <nav className="navbar glass">
        <div className="navbar-container">
          <NavLink to="/" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
            Main
          </NavLink>
          <NavLink to="/experience" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
            Experience
          </NavLink>
          <NavLink to="/contact" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
            Contact
          </NavLink>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
